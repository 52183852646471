body {
  color: #000000;
  font: normal 16px/1.4 "Montserrat", "Helvetica", "Arial", sans-serif, "Helvetica", "Arial", sans-serif;
  letter-spacing: .02em;
}
.hwui-UrgentCareScheduling-regionName-50 {
  font-size: 1.4rem;
  font-weight: 700;
}
.MuiTypography-h3 {
  font-size: 2rem;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.167;
}
.MuiTypography-root {
  margin: 0;
}
h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000000;
}
* {
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}
h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.hwui-UrgentCareScheduling-header-1 {
  height: 10px;
  margin: 20px 0.5rem;
  font-size: 1.5rem;
  text-align: left;
  font-weight: 400;
}
.MuiTypography-h1 {
  font-size: 1.25rem;
  font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.167;
}
h1 {
  font: normal 16px/1.4 "Montserrat", "Helvetica", "Arial", sans-serif, "Helvetica", "Arial", sans-serif;
  font-size: 2em;
  margin: 0.67em 0;
}
.hwui-UrgentCareScheduling-accordionSummary-54 {
  height: 50px;
  border-bottom: 1px solid #000000;
}
p{
  margin: 0.67em
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: .5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal {
  z-index: 100;
  background: white;
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 500px;
  padding: 2rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: .3;
  cursor: pointer;
  border: none;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}