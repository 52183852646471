.container {
  justify-content: space-between;
}

.vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.customButton {
  font-family: Montserrat;
  font-weight: 400;
  background-color: #FFFFFF;
  color: #006298;
  border-color: #006298;
  border-style: solid;
  padding: 8px 16px;
  border-radius: 16px;
  margin: 4px 2px;
}

.active{
  background-color: #eef7fc;
  color: #179bd3;
  border-color: #179bd3;
}

.customButton:hover{
  background-color: #eef7fc;
  color: #179bd3;
  border-color: #179bd3;
}
